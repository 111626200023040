// client/src/components/SGD/ContainerDetailsModal.js
import React from 'react';
import styles from '../BoxDetails/BoxDetailsModal.module.css';

const ContainerDetailsModal = ({ container, onClose, onEditClick }) => {
  if (!container) return null;

  // Attempt to parse reference_numbers if it's stored as a JSON string
  let referenceNumbers;
  try {
    referenceNumbers = typeof container.reference_numbers === 'string'
      ? JSON.parse(container.reference_numbers)
      : container.reference_numbers;
  } catch (error) {
    console.error('Error parsing reference numbers:', error);
    referenceNumbers = []; // Fallback to an empty array to prevent rendering errors
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>Container #{container.container_number}</h2>
        </div>
        <div className={styles.modalContent}>
          {container.location_description && (
            <div>
              <strong>Location Description:</strong> {container.location_description}
            </div>
          )}
          <ul>
            {Array.isArray(referenceNumbers) && referenceNumbers.map((refNum, index) => (
              <li key={index}>Reference Number: {refNum}</li> // Assuming refNum is a string
            ))}
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.closeButton} onClick={onClose}>Close</button>
          <button className={styles.editButton} onClick={() => onEditClick(container)}>Edit</button>
        </div>
      </div>
    </div>
  );
};

export default ContainerDetailsModal;

