import React, { useState } from 'react';
import MasterUserManagement from './MasterUserManagement.js';
import MasterClientManagement from './MasterClientManagement.js';
import MasterArchive from './MasterArchive.js'; 
import styles from '../AdminDashboard/AdminDashboard.module.css';
import MasterExport from './MasterExport.js';
import MasterLocationManagement from './MasterLocationManagement.js';

const MasterDashboard = () => {
    const [activeTab, setActiveTab] = useState('users');

    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={styles.adminDashboard}>
            <nav className={styles.adminNav}>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('users')}
                    disabled={activeTab === 'users'}
                >
                    User Management
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('clients')}
                    disabled={activeTab === 'clients'}
                >
                    Client Management
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('locations')}
                    disabled={activeTab === 'locations'}
                >
                    Location Management
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('archive')}
                    disabled={activeTab === 'archive'}
                >
                    Archive
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('export')}
                    disabled={activeTab === 'export'}
                >
                    Export
                </button>
            </nav>
            <div className={styles.adminContent}>
                {activeTab === 'users' && <MasterUserManagement />}
                {activeTab === 'clients' && <MasterClientManagement />}
                {activeTab === 'locations' && <MasterLocationManagement />}
                {activeTab === 'archive' && <MasterArchive />}  
                {activeTab === 'export' && <MasterExport />}
            </div>
        </div>
    );
};

export default MasterDashboard;