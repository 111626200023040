// PasswordResetModal.js

import React, { useState } from 'react';
import styles from './MasterUserManagement.module.css';

const PasswordResetModal = ({ isOpen, onClose, userId }) => {
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!userId || !newPassword) {
            alert('Please select a user and enter a new password.');
            return;
        }

        try {
            const response = await fetch(`/api/master/users/${userId}/reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ newPassword: newPassword }),
            });

            if (response.ok) {
                alert('Password updated successfully');
            } else {
                const errorResponse = await response.json();
                console.error('Failed to update user password:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error updating user password:', error);
        } finally {
            onClose(); 
        }
    };

    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalBody}>
                <h2>Reset Password</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="password"
                        placeholder="Enter new password"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={styles.passwordInput}
                    />
                    <div className={styles.modalActions}>
                        <button type="submit" className={styles.updateButton}>Update</button>
                        <button type="button" onClick={onClose} className={styles.cancelButton}>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PasswordResetModal;