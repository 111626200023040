import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../LandingPage/LandingPage.module.css';

const SgdLanding = () => {
  const navigate = useNavigate();

  const navigateToInbound = () => {
    navigate('/sgd-inbound');
  };

  const navigateToInventory = () => {
    navigate('/sgd-inventory'); 
  };

  const navigateToExport = () => {
    navigate('/sgd-export');
  };

  return (
    <div className={styles.landingPage}>
      <div className={styles.welcomeMessage}>Welcome SGD</div>
      <button onClick={navigateToInbound} className={styles.landingPageButton}>Inbound</button>
      <button onClick={navigateToInventory} className={styles.landingPageButton}>Inventory</button>
      <button onClick={navigateToExport} className={styles.landingPageButton}>Export Data</button>
    </div>
  );
};

export default SgdLanding;
