import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import styles from './Login.module.css'; // Corrected import statement

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/login', { username, password }, {
        withCredentials: true 
      });
      if (response.data.success) {
        onLogin(response.data.user); 
        navigate('/');
      } else {
        alert('Login failed: ' + response.data.message);
      }
    } catch (error) {
      // Additional checks for error details
      if (error.response) {
        console.error('Error response:', error.response);
        alert('Login failed: ' + (error.response.data.message || 'Server error'));
      } else if (error.request) {
        console.error('Error request:', error.request);
        alert('Login failed: Server did not respond');
      } else {
        console.error('Error', error.message);
        alert('Login failed: ' + error.message);
      }
    }
  };

  return (
    <div className={styles.loginFormContainer}>
      <div className={styles.loginForm}>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className={`${styles.loginFormField} ${isUsernameFocused || username ? styles.focused : ''}`}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onFocus={() => setIsUsernameFocused(true)}
              onBlur={() => setIsUsernameFocused(false)}
            />
          </div>
          <div className={`${styles.loginFormField} ${isPasswordFocused || password ? styles.focused : ''}`}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
            />
          </div>
          <button className={styles.loginFormButton} type="submit">Login</button>
        </form>
        <p>Don't have an account? <Link to="/register">Register</Link></p>
      </div>
    </div>
  );
};

export default Login;