// client/src/components/BarcodeGenerator/BarcodeGenerator.js
import React, { useState } from 'react';
import styles from './BarcodeGenerator.module.css'; // Update this path if necessary

const BarcodeGenerator = () => {
    const [sku, setSku] = useState('');
    const [labelSize, setLabelSize] = useState('4x6');
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Function to handle ZPL file download
    const handleDownloadZPL = async () => {
        try {
            const query = `/api/barcode/download-zpl?sku=${encodeURIComponent(sku)}&labelSize=${encodeURIComponent(labelSize)}&url=${encodeURIComponent(url)}`;
            const response = await fetch(query);
            if (!response.ok) throw new Error('Failed to download ZPL');
            const zplBlob = await response.blob();
            const zplUrl = window.URL.createObjectURL(zplBlob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = zplUrl;
            a.download = `${sku}-label.zpl`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(zplUrl);
            a.remove();
        } catch (error) {
            console.error('Error downloading ZPL:', error);
        }
    };

    /*

    // Function to handle print to Zebra
    const handlePrint = async () => {
        try {
            setLoading(true);
            const query = `/api/barcode/print-zpl?sku=${encodeURIComponent(sku)}&labelSize=${encodeURIComponent(labelSize)}&url=${encodeURIComponent(url)}`;
            const response = await fetch(query);
            if (!response.ok) throw new Error('Failed to generate ZPL');
            const zplData = await response.json();
            sendZPLToPrinter(zplData.zpl);
        } catch (error) {
            console.error('Error while printing ZPL:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    // Function to send ZPL to the printer
    const sendZPLToPrinter = (zpl) => {
        // Define the IP address and port of your Zebra printer
        const printerIP = '192.168.0.27';
        const printerPort = 9100;

        // Create a WebSocket connection to the printer
        const socket = new WebSocket(`wss://${printerIP}:${printerPort}`);
        socket.onopen = () => {
            socket.send(zpl); // Send the ZPL to the printer
            socket.close();
        };
        socket.onerror = (error) => {
            console.error('Socket error:', error);
        };
    };

    */

    // Get current date in YYYY-MM-DD format
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <div className={styles.barcodeGeneratorContainer}>
            <div className={styles.generatorForm}>
                <h1 className="text-center my-4">Barcode Generator</h1>
                <form onSubmit={handleSubmit}>
                    <div className={styles.formGroup}>
                        <label htmlFor="skuInput" className={styles.formLabel}>Enter SKU:</label>
                        <input
                            type="text"
                            className={styles.formControl}
                            id="skuInput"
                            value={sku}
                            onChange={(e) => setSku(e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="urlInput" className={styles.formLabel}>Enter URL or Description (optional):</label>
                        <input
                            type="text"
                            className={styles.formControl}
                            id="urlInput"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="labelSizeSelect" className={styles.formLabel}>Label Size:</label>
                        <select
                            className={styles.formSelect}
                            id="labelSizeSelect"
                            value={labelSize}
                            onChange={(e) => setLabelSize(e.target.value)}
                        >
                            <option value="4x6">4" x 6"</option>
                            <option value="1.5x1.5">1.5" x 1.5"</option>
                            <option value="1.5x1">1.5" x 1"</option>
                        </select>
                    </div>
                </form>
                {loading && <p>Loading...</p>}
                {error && <p className={`${styles.error} error`}>Error: {error.message}</p>}
                {sku && (
                    <>
                        <button onClick={handleDownloadZPL} className={`${styles.submitBtn} btn btn-success btn-block`} disabled={loading /*|| !image*/}>
                            Download ZPL Label
                        </button>
                        {/*
                        <button onClick={handlePrint} className={`${styles.submitBtn} btn btn-primary btn-block`} disabled={loading}>
                            Print Label
                        </button>
                        */}
                    </>
                )}
            </div>
        </div>
    );
};

export default BarcodeGenerator;


