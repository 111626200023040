import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from "react-icons/io5";
import styles from '../UserManagement/UserManagement.module.css'; // Reuse UserManagement styles

const ClientManagement = () => {
    const [clients, setClients] = useState([]);
    const [clientName, setClientName] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        fetchClients();
    }, []);

    const fetchClients = async () => {
        try {
            const response = await fetch('/api/admin/clients', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setClients(data);
            } else {
                console.error('Failed to fetch clients. Status:', response.status);
                const text = await response.text(); 
                console.error('Response body:', text);
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const addClient = async () => {
        try {
            const response = await fetch('/api/admin/clients', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name: clientName }),
                credentials: 'include'
            });
            if (response.ok) {
                fetchClients(); 
                setClientName(''); 
            } else {
                console.error('Failed to add client:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding client:', error);
        }
    };

    const deleteClient = async (clientId) => {
        try {
            const response = await fetch(`/api/admin/clients/${clientId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            if (response.ok) {
                fetchClients(); 
            } else {
                console.error('Failed to delete client:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting client:', error);
        }
    };

    const handleEditToggle = () => {
        setIsEditMode(!isEditMode);
    };

    return (
        <div className={styles.userManagement}>
            <h2>Client Management</h2>
            <div className={styles.userManagementHeader}>
                <div className={styles.userManagementForm}>
                    <input 
                        type="text" 
                        placeholder="Client Name" 
                        value={clientName} 
                        onChange={(e) => setClientName(e.target.value)} 
                    />
                    <button onClick={addClient}>Add Client</button>
                    <button onClick={handleEditToggle} className={styles.editButton}>
                        {isEditMode ? "Done" : "Edit"}
                    </button>
                </div>
            </div>
            <div className={styles.columnTitles}>
                <span>Client Name</span>
            </div>
            <ul className={styles.userList}>
                {clients.map(client => (
                    <li key={client.id} className={styles.userListItem}>
                        <span>{client.name}</span>
                        {isEditMode && (
                            <button onClick={() => deleteClient(client.id)} className={styles.deleteButton}>
                                <IoCloseCircleOutline />
                            </button>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClientManagement;
