import React, { useState, useEffect } from 'react';
import styles from './NewForm.module.css';
import { IoCloseCircleOutline } from "react-icons/io5";

const NewForm = ({ onAddBox, onCancel }) => {
  const [referenceNumber, setReferenceNumber] = useState('');
  const [newSku, setNewSku] = useState('');
  const [skus, setSkus] = useState([]);
  const [skuTimeout, setSkuTimeout] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [locationDescription, setLocationDescription] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch('/api/clients');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setClients(data.map(client => ({ id: client.id, name: client.name })));
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };
  
    fetchClients();
  }, []);  

  useEffect(() => {
    clearTimeout(skuTimeout);
    if (newSku) {
      const timeout = setTimeout(() => {
        if (newSku.trim() !== '') {
          setSkus(prevSkus => [{ sku: newSku.trim() }, ...prevSkus]);
          setNewSku('');
        }
      }, 200);
      setSkuTimeout(timeout);
    }
  }, [newSku]);

  const handleReferenceNumberChange = (e) => {
    setReferenceNumber(e.target.value);
  };

  const handleNewSkuChange = (e) => {
    setNewSku(e.target.value);
  };

  const handleNewSkuKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (newSku.trim() !== '') {
        setSkus(prevSkus => [{ sku: newSku.trim() }, ...prevSkus]);
        setNewSku('');
      }
    }
  };

  const handleRemoveSku = (indexToRemove) => {
    setSkus(skus.filter((_, index) => index !== indexToRemove));
  };

  const handleBackClick = () => {
    onCancel();
  };

  const handleClientChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const client = clients.find(c => c.name === selectedClient);
  
    // Check for client selection
    if (!client) {
      alert('Please select a client.');
      return;
    }
  
    // Check for a non-empty reference number
    if (!referenceNumber.trim()) {
      alert('Please enter a reference number.');
      return;
    }
  
    // If validation passes, proceed with the form submission
    try {
      const payload = {
        client_id: client.id,
        referenceNumber,
        skus,
        location_description: locationDescription,
        tracking_number: trackingNumber,
      };
  
      const response = await fetch('/api/returns', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
  
      const result = await response.json();
  
      // Handling success
      if (response.ok) {
        onAddBox(result);
        setSelectedClient('');
        setReferenceNumber('');
        setSkus([]);
        alert('Submission successful!');
      } else if (response.status === 400) {
        // Handling the specific error when a reference number is already used
        alert(result.message);
      } else {
        // Handling other possible errors
        console.error('Failed to save data:', result);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }
  };      

  return (
    <div className={styles.newFormContainer}>
      <form onSubmit={handleSubmit} className={styles.newForm}>

        <div className={styles.inputGroup}>
          <select value={selectedClient} onChange={handleClientChange} className={styles.referenceInput} required>
          <option value="" disabled>Select Client</option>
            {clients.map(client => (
              <option key={client.id} value={client.name}>{client.name}</option>
            ))}
          </select>
        </div>

        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Reference number (required)"
            value={referenceNumber}
            onChange={handleReferenceNumberChange}
            className={styles.referenceInput}
          />
        </div>
        
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Tracking Number (optional)"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
            className={styles.referenceInput}
          />
        </div>

        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Location (optional)"
            value={locationDescription}
            onChange={(e) => setLocationDescription(e.target.value)}
            className={styles.referenceInput}
          />
        </div>

        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Enter SKUs..."
            value={newSku}
            onChange={handleNewSkuChange}
            onKeyPress={handleNewSkuKeyPress}
            className={styles.skuInput}
          />
        </div>
        
        <div className={styles.skuCount}>
          <p>SKUs Scanned: {skus.length}</p>
        </div>

        <div className={styles.skuList}>
          {skus.map((item, index) => (
            <div className={styles.skuItem} key={`sku-${index}`}>
              <span className={styles.skuText}>SKU: {item.sku}</span>
              <button
                type="button"
                onClick={() => handleRemoveSku(index)}
                className={styles.removeSkuButton}
              >
                <IoCloseCircleOutline />
              </button>
            </div>
          ))}
        </div>

        <div className={styles.buttonGroup}>
          <button 
            type="button" 
            onClick={handleBackClick}
            className={styles.backButton}
          >
            Back
          </button>
          <button 
            type="submit" 
            className={styles.submitButton}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewForm;