// client/src/components/BoxDetails/BoxDetailsModal.js
import React, { useState, useEffect } from 'react';
import styles from './BoxDetailsModal.module.css';

const BoxDetailsModal = ({ box, onClose, onEditClick, getClientName }) => {
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    const fetchClientName = async () => {
      const name = await getClientName(box.client_id);
      setClientName(name);
    };

    if (box && box.client_id) {
      fetchClientName();
    }
  }, [box, getClientName]);

  if (!box) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>{clientName && `Client: ${clientName}`}</h2>
          <h2>Ref #{box.reference_number}</h2>
        </div>
        <div className={styles.modalContent}>
          {box.location_description && (
            <div>
              <strong>Location Description:</strong> {box.location_description}
            </div>
          )}
          <ul>
            {box.skus.map((sku, index) => (
              <li key={index}>SKU: {sku.sku}</li>
            ))}
          </ul>
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.closeButton} onClick={onClose}>Close</button>
          <button className={styles.editButton} onClick={() => onEditClick(box)}>Edit</button>
        </div>
      </div>
    </div>
  );
};

export default BoxDetailsModal;