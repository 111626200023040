import React, { useState, useEffect } from 'react';
import styles from '../NewReturnForm/NewForm.module.css'; 
import { IoCloseCircleOutline, IoDownloadOutline } from "react-icons/io5";

const NewContainerForm = ({ onAddContainer, onCancel }) => {
  const [containerNumber, setContainerNumber] = useState('');
  const [newReferenceNumber, setNewReferenceNumber] = useState('');
  const [referenceNumbers, setReferenceNumbers] = useState([]);
  const [referenceNumberTimeout, setReferenceNumberTimeout] = useState(null);

  useEffect(() => {
    clearTimeout(referenceNumberTimeout);
    if (newReferenceNumber) {
      const timeout = setTimeout(() => {
        if (newReferenceNumber.trim() !== '') {
          setReferenceNumbers(prevRefNums => [...prevRefNums, newReferenceNumber.trim()]);
          setNewReferenceNumber('');
        }
      }, 200);
      setReferenceNumberTimeout(timeout);
    }
  }, [newReferenceNumber]);

  const handleContainerNumberChange = (e) => {
    setContainerNumber(e.target.value);
  };

  const handleNewReferenceNumberChange = (e) => {
    setNewReferenceNumber(e.target.value);
  };

  const handleNewReferenceNumberKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (newReferenceNumber.trim() !== '') {
        setReferenceNumbers(prevRefNums => [...prevRefNums, newReferenceNumber.trim()]);
        setNewReferenceNumber('');
      }
    }
  };

  const handleRemoveReferenceNumber = (indexToRemove) => {
    setReferenceNumbers(referenceNumbers.filter((_, index) => index !== indexToRemove));
  };

  const handleBackClick = () => {
    onCancel();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (containerNumber.trim() && referenceNumbers.length > 0) {
      try {
        const payload = {
          containerNumber, 
          referenceNumbers,
          locationId: 5 // Assuming locationId is static for this example. Adjust as needed.
        };
  
        const response = await fetch('/api/sgd_inventory', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          const newContainer = await response.json();
          onAddContainer(newContainer); // Assuming this function is designed to update parent state
          setContainerNumber('');
          setReferenceNumbers([]);
        } else {
          const errorText = await response.text();
          console.error('Failed to add new container:', errorText);
          alert('Failed to add new container.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while adding the new container.');
      }
    } else {
      alert('Please enter a container number and at least one reference number.');
    }
  };    

  return (
    <div className={styles.newFormContainer}>
      <form onSubmit={handleSubmit} className={styles.newForm}>
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Container number (required)"
            value={containerNumber}
            onChange={handleContainerNumberChange}
            className={styles.referenceInput}
          />
        </div>
        
        <div className={styles.inputGroup}>
          <input
            type="text"
            placeholder="Enter reference numbers..."
            value={newReferenceNumber}
            onChange={handleNewReferenceNumberChange}
            onKeyPress={handleNewReferenceNumberKeyPress}
            className={styles.skuInput}
            autoFocus
          />
        </div>

        <div className={styles.skuList}>
          {referenceNumbers.map((refNum, index) => (
            <div className={styles.skuItem} key={`refNum-${index}`}>
              <span className={styles.skuText}>{refNum}</span>
              <button
                type="button"
                onClick={() => handleRemoveReferenceNumber(index)}
                className={styles.removeSkuButton}
              >
                <IoCloseCircleOutline />
              </button>
            </div>
          ))}
        </div>

        <div className={styles.buttonGroup}>
          <button 
            type="button" 
            onClick={handleBackClick}
            className={styles.backButton}
          >
            Back
          </button>
          <button 
            type="submit" 
            className={styles.submitButton}
          >
            Submit<IoDownloadOutline style={{ fontSize: '1.2em' }} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewContainerForm;
