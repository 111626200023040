import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from "react-icons/io5";
import styles from './UserManagement.module.css';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch('/api/admin/users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include' 
            });
            if (response.ok) {
                const data = await response.json();
                setUsers(data);
            } else {
                console.error('Failed to fetch users: ', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const toggleAdminStatus = async (userId, isAdmin) => {
        try {
            const response = await fetch(`/api/admin/users/${userId}/toggle-admin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ isAdmin: !isAdmin }) 
            });
            if (response.ok) {
                fetchUsers(); 
            } else {
                const errorResponse = await response.json();
                console.error('Failed to toggle admin status:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error toggling admin status:', error);
        }
    };

    const addUser = async () => {
        try {
            const response = await fetch('/api/admin/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password }),
                credentials: 'include'
            });
            if (response.ok) {
                fetchUsers(); 
            } else {
                const errorResponse = await response.json();
                console.error('Failed to add user:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };
    
    const deleteUser = async (userId) => {
        try {
            const response = await fetch(`/api/admin/users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            if (response.ok) {
                fetchUsers(); 
            } else {
                const errorResponse = await response.json();
                console.error('Failed to delete user:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    }; 

    const handleEditToggle = () => {
        setIsEditMode(!isEditMode);
    };

    return (
        <div className={styles.userManagement}>
            <h2>User Management</h2>
            <div className={styles.userManagementHeader}>
                <div className={styles.userManagementForm}>
                    <input 
                        type="text" 
                        placeholder="Username" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                    />
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                    <button onClick={addUser}>Add User</button>
                    <button onClick={handleEditToggle} className={styles.editButton}>
                        {isEditMode ? "Done" : "Edit"}
                    </button>
                </div>
            </div>
            <div className={styles.columnTitles}>
                <span>Employee Name</span>
                <span>Admin</span>
            </div>
            <ul className={styles.userList}>
                {users.map(user => (
                    <li key={user.id} className={styles.userListItem}>
                        <span>{user.username}</span>
                        <div className={styles.userControls}>
                            <label className={styles.switch}>
                                <input 
                                    type="checkbox" 
                                    checked={user.is_admin} 
                                    onChange={() => toggleAdminStatus(user.id, user.is_admin)} 
                                />
                                <span className={`${styles.slider} ${styles.round}`}></span>
                            </label>
                            {isEditMode && <button onClick={() => deleteUser(user.id)} className={styles.deleteButton}><IoCloseCircleOutline /></button>}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserManagement;

