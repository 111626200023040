import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../LandingPage/LandingPage.module.css';

const ReflauntLanding = () => {
  const navigate = useNavigate();

  const navigateToBarcode = () => {
    navigate('/barcode-generator');
  };

  const navigateToOrderProcessor = () => {
    navigate('/reflaunt-orders'); 
  };

  return (
    <div className={styles.landingPage}>
      <div className={styles.welcomeMessage}>Welcome Reflaunt</div>
      <button onClick={navigateToBarcode} className={styles.landingPageButton}>Label Generator</button>
      <button onClick={navigateToOrderProcessor} className={styles.landingPageButton}>Order Form Processor</button>
    </div>
  );
};

export default ReflauntLanding;