import React, { useState, useEffect } from 'react';
import styles from '../EditBoxForm/EditBoxForm.module.css'; 
import { IoCloseCircleOutline } from "react-icons/io5";

const EditContainerForm = ({ container, onUpdateContainer, onClose }) => {
    const [containerNumber, setContainerNumber] = useState('');
    const [newReferenceNumber, setNewReferenceNumber] = useState('');
    const [referenceNumbers, setReferenceNumbers] = useState([]);

    useEffect(() => {
        if (container) {
          setContainerNumber(container.container_number);
          let refNumsArray;
          try {
            refNumsArray = typeof container.reference_numbers === 'string'
              ? JSON.parse(container.reference_numbers)
              : container.reference_numbers;
          } catch (error) {
            console.error('Error parsing reference numbers:', error);
            refNumsArray = []; 
          }
          setReferenceNumbers(refNumsArray);
        }
    }, [container]);
    
    const handleContainerNumberChange = (e) => {
      setContainerNumber(e.target.value);
    };

    const handleNewReferenceNumberChange = (e) => {
      setNewReferenceNumber(e.target.value);
    };

    const handleAddReferenceNumber = () => {
      if (newReferenceNumber.trim() !== '') {
        setReferenceNumbers([...referenceNumbers, newReferenceNumber.trim()]);
        setNewReferenceNumber('');
      }
    };

    const handleRemoveReferenceNumber = (indexToRemove) => {
      setReferenceNumbers((prevRefNums) => prevRefNums.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (containerNumber.trim()) {
        try {
          const updatedContainer = {
            containerNumber, 
            referenceNumbers,
            locationId: container.location_id, // Assuming location_id is part of your container object
          };
          const response = await fetch(`/api/sgd_inventory/${container.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updatedContainer),
          });
    
          if (response.ok) {
            const result = await response.json();
            onUpdateContainer(result);
            onClose();
          } else {
            console.error('Failed to update container:', await response.text());
          }
        } catch (error) {
          console.error('Error:', error);
        }
      } else {
        alert('Please enter a container number.');
      }
    };    

    return (
        <div className={styles.editFormContainer}>
          <form onSubmit={handleSubmit} className={styles.editForm}>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  placeholder="Container number (required)"
                  value={containerNumber}
                  onChange={handleContainerNumberChange}
                  className={styles.referenceInput}
                />
              </div>

              <div className={styles.inputGroup}>
                <input
                  type="text"
                  placeholder="Add new reference number..."
                  value={newReferenceNumber}
                  onChange={handleNewReferenceNumberChange}
                  className={styles.skuInput}
                />
                <button 
                  type="button" 
                  onClick={handleAddReferenceNumber} 
                  className={styles.addSkuButton}
                >
                  Add
                </button>
              </div>

              <div className={styles.skuList}>
                {referenceNumbers.map((refNum, index) => (
                  <div key={index} className={styles.skuItem}>
                    <span className={styles.skuText}>Ref: {refNum}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveReferenceNumber(index)}
                      className={styles.removeSkuButton}
                    >
                      <IoCloseCircleOutline />
                    </button>
                  </div>
                ))}
              </div>

              <div className={styles.buttonGroup}>
                <button 
                  type="button" 
                  onClick={onClose}
                  className={styles.backButton}
                >
                  Cancel
                </button>
                <button 
                  type="submit" 
                  className={styles.submitButton}
                >
                  Update
                </button>
              </div>
          </form>
        </div>
    );
};

export default EditContainerForm;
