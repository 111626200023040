import React, { useState, useEffect } from 'react';
import styles from './EditBoxForm.module.css';
import { IoCloseCircleOutline } from "react-icons/io5";

const EditBoxForm = ({ box, onUpdateBox, onClose }) => {
  const [referenceNumber, setReferenceNumber] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [newSku, setNewSku] = useState('');
  const [skus, setSkus] = useState([]);
  const [skuTimeout, setSkuTimeout] = useState(null);
  const [locationDescription, setLocationDescription] = useState('');
  const [trackingNumber, setTrackingNumber] = useState('');
  
  useEffect(() => {
    if (box) {
      setReferenceNumber(box.reference_number);
      setSkus(box.skus);
      setLocationDescription(box.location_description || '');
      setTrackingNumber(box.tracking_number || '');
      setSelectedClient(box.client_id);
    }
  }, [box]);

  useEffect(() => {
    clearTimeout(skuTimeout);
    if (newSku) {
      const timeout = setTimeout(() => {
        if (newSku.trim() !== '') {
          setSkus(prevSkus => [{ sku: newSku.trim() }, ...prevSkus]);
          setNewSku('');
        }
      }, 200);
      setSkuTimeout(timeout);
    }
  }, [newSku]);

  const handleReferenceNumberChange = (e) => {
    setReferenceNumber(e.target.value);
  };

  const handleNewSkuChange = (e) => {
    setNewSku(e.target.value);
  };
  const handleNewSkuKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (newSku.trim() !== '') {
        setSkus(prevSkus => [{ sku: newSku.trim() }, ...prevSkus]);
        setNewSku('');
      }
    }
  };

  const handleLocationDescriptionChange = (e) => {
    setLocationDescription(e.target.value);
  };

  const handleTrackingNumberChange = (e) => {
    setTrackingNumber(e.target.value);
  };

  const handleAddSku = () => {
    if (newSku.trim() !== '') {
      setSkus([...skus, { sku: newSku.trim() }]);
      setNewSku('');
    }
  };

  const handleRemoveSku = (indexToRemove) => {
    setSkus((prevSkus) => prevSkus.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!referenceNumber.trim()) {
      alert('Please enter a reference number.');
      return;
    }
  
    try {
      const updatedBox = {
        client_id: selectedClient,
        referenceNumber,
        skus,
        location_description: locationDescription,
        tracking_number: trackingNumber,
      };
  
      const response = await fetch(`/api/returns/${box.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedBox),
      });
  
      if (response.ok) {
        const result = await response.json();
        onUpdateBox(result);
        onClose();
        alert('Update successful!');
      } else if (response.status === 400) {
        const result = await response.json();
        alert(result.message); 
      } else {
        console.error('Failed to update box:', await response.text());
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while updating the box. Please try again.');
    }
  };    
  return (
      <div className={styles.editFormContainer}>
        <form onSubmit={handleSubmit} className={styles.editForm}>        
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Input reference number"
              value={referenceNumber}
              onChange={handleReferenceNumberChange}
              className={styles.referenceInput}
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Tracking Number (optional)"
              value={trackingNumber}
              onChange={handleTrackingNumberChange}
              className={styles.referenceInput}
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Location (optional)"
              value={locationDescription}
              onChange={handleLocationDescriptionChange}
              className={styles.referenceInput}
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              type="text"
              placeholder="Enter SKUs..."
              value={newSku}
              onChange={handleNewSkuChange}
              onKeyPress={handleNewSkuKeyPress}
              className={styles.skuInput}
            />
          </div>
          <div className={styles.skuCount}>
            <p>SKUs Scanned: {skus.length}</p>
          </div>
          <div className={styles.skuList}>
            {skus.map((item, index) => (
              <div className={styles.skuItem} key={`sku-${index}`}>
                <span className={styles.skuText}>SKU: {item.sku}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveSku(index)}
                  className={styles.removeSkuButton}
                >
                  <IoCloseCircleOutline />
                </button>
              </div>
            ))}
          </div>
          <div className={styles.buttonGroup}>
            <button 
              type="button" 
              onClick={onClose}
              className={styles.backButton}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className={styles.submitButton}
            >
              Update
            </button>
          </div>
        </form>
      </div>
  );
}
export default EditBoxForm;
