import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

function ReflauntOrder() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(''); // Add state to store the file name

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0] ? e.target.files[0].name : ''); // Update file name state
  };

  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file first.');
      return;
    }

    try {
      const response = await fetch('/api/upload-excel', { // Ensure this matches your server endpoint
        method: 'POST',
        headers: {
          // 'Content-Type' header can be omitted as fetch will automatically set it
          // to 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          // if you're sending binary data directly
        },
        body: file, // Send the file directly as binary data
      });

      if (response.ok) {
        const blob = await response.blob();
        // Create a new URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);
        // Create a temporary <a> element and trigger the download
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'ReflauntOrders.xlsx'; // Name the downloaded file
        document.body.appendChild(a); // Append to the document
        a.click(); // Trigger the download
        a.remove(); // Clean up
      } else {
        alert('Upload failed');
      }
    } catch (error) {
      alert('Upload failed');
      console.error('Error:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <div className="card">
            <div className="card-header">
              Upload Order Form
            </div>
            <div className="card-body">
              <div className="custom-file">
                <input type="file" className="custom-file-input" id="customFile" onChange={handleFileChange} />
              </div>
            </div>
            <div className="card-footer text-right">
              <button className="btn btn-success" onClick={handleUpload}>Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReflauntOrder;
