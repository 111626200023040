import React from 'react';
import styles from './DisplayList.module.css';
import { IoCloseCircleOutline } from 'react-icons/io5';

const DisplayList = ({ boxes, onDelete, onBoxClick }) => {
  const handleDelete = (event, box) => {
    event.stopPropagation();
    onDelete(box); 
  };

  return (
    <div className={styles.listBoxContainer}>
      <ul className={styles.listBox}>
        {boxes.map((box) => (
          <li key={box.id} className={styles.listItem} onClick={() => onBoxClick(box)}>
            Ref #{box.reference_number}
            <button onClick={(event) => handleDelete(event, box)} className={styles.deleteButton}>
              <IoCloseCircleOutline size={20} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DisplayList;
