import React, { useState, useEffect } from 'react';
import styles from '../AdminExport/AdminExport.module.css';

const MasterExport = () => {
    const [users, setUsers] = useState([]);
    const [clients, setClients] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selectedUser, setSelectedUser] = useState('all');
    const [selectedClient, setSelectedClient] = useState('all');
    const [selectedLocation, setSelectedLocation] = useState('all');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetch('/api/master/users', {
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsers(data);
                } else {
                    console.error('Failed to fetch users');
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const fetchClients = async () => {
            try {
                const response = await fetch('/api/master/clients', {
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = await response.json();
                    setClients(data);
                } else {
                    console.error('Failed to fetch clients');
                }
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        const fetchLocations = async () => {
            try {
                const response = await fetch('/api/master/locations', {
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include'
                });
                if (response.ok) {
                    const data = await response.json();
                    setLocations(data);
                } else {
                    console.error('Failed to fetch locations');
                }
            } catch (error) {
                console.error('Error fetching locations:', error);
            }
        };

        fetchUsers();
        fetchClients();
        fetchLocations();
    }, []);

    const handleExportAllReturns = async () => {
        try {
            const response = await fetch('/api/master/export-returns', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({
                    userId: selectedUser === 'all' ? null : selectedUser,
                    clientId: selectedClient === 'all' ? null : selectedClient,
                    locationId: selectedLocation === 'all' ? null : selectedLocation,
                    startDate: startDate || null,
                    endDate: endDate || null
                })
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'all_returns.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to export all returns:', response.statusText);
            }
        } catch (error) {
            console.error('Error exporting all returns:', error);
        }
    };    

    return (
        <div className={styles.returnsManagement}>
            <h2>Export Management</h2>
            <div className={styles.filterContainer}>
                <select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    className={styles.userDropdown}
                >
                    <option value="all">All Locations</option>
                    {locations.map(location => (
                        <option key={location.id} value={location.id}>{location.location_name}</option>
                    ))}
                </select>

                <select
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                    className={styles.userDropdown}
                >
                    <option value="all">All Users</option>
                    {users.map(user => (
                        <option key={user.id} value={user.id}>{user.username}</option>
                    ))}
                </select>

                <select
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    className={styles.userDropdown}
                >
                    <option value="all">All Clients</option>
                    {clients.map(client => (
                        <option key={client.id} value={client.id}>{client.name}</option>
                    ))}
                </select>

                <input 
                    type="date" 
                    value={startDate} 
                    onChange={(e) => setStartDate(e.target.value)}
                    className={styles.dateInput}
                />
                <input 
                    type="date" 
                    value={endDate} 
                    onChange={(e) => setEndDate(e.target.value)}
                    className={styles.dateInput}
                />

                <button className={styles.exportButton} onClick={handleExportAllReturns}>
                    Export
                </button>
            </div>
        </div>
    );
};

export default MasterExport;