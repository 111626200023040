// client/src/components/ReturnPage/ReturnPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NewForm from '../NewReturnForm/NewForm';
import DisplayList from '../DisplayList/DisplayList';
import BoxDetailsModal from '../BoxDetails/BoxDetailsModal';
import EditBoxForm from '../EditBoxForm/EditBoxForm';
import styles from './ReturnPage.module.css';
import { IoCreateOutline, IoDownloadOutline, IoSearchOutline } from "react-icons/io5";

const ReturnPage = () => {
  const [boxes, setBoxes] = useState([]);
  const [filteredBoxes, setFilteredBoxes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [isCreatingNewReturn, setIsCreatingNewReturn] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBoxes = async () => {
      try {
        const response = await fetch('/api/returns');
        if (response.ok) {
          const data = await response.json();
          const boxesToSet = searchQuery
            ? data.filter(box => box.reference_number.toLowerCase().includes(searchQuery.toLowerCase()))
            : data;
          setBoxes(boxesToSet);
          setFilteredBoxes(boxesToSet); 
        } else {
          console.error('Failed to fetch the boxes.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    fetchBoxes();
  }, [searchQuery]);

  const navToLanding = () => {
    navigate('/'); 
  };

  const handleSearchIconClick = () => {
    setShowSearch(true); 
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getClientName = async (clientId) => {
    try {
      const response = await fetch(`/api/clients/${clientId}`);
      if (response.ok) {
        const client = await response.json();
        return client.name;
      }
    } catch (error) {
      console.error('Error fetching client name:', error);
    }
    return '';
  };

  const addBox = (newBox) => {
    setBoxes(prevBoxes => [...prevBoxes, newBox]);
    setIsCreatingNewReturn(false); 
  };

  const deleteBox = async (box) => {
    if (window.confirm(`Are you sure? Ref #${box.reference_number} will be gone forever.`)) {
      try {
        const response = await fetch(`/api/returns/${box.id}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          setBoxes(boxes.filter(currentBox => currentBox.id !== box.id));
        } else {
          console.error('Failed to delete the box.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const updateBox = (updatedBox) => {
    setBoxes(prevBoxes =>
      prevBoxes.map(box => box.id === updatedBox.id ? updatedBox : box)
    );
  
    if (selectedBox && selectedBox.id === updatedBox.id) {
      setSelectedBox(updatedBox);
    }
  };

  const handleBoxClick = (box) => {
    setSelectedBox(box); 
  };

  const handleBackClick = () => {
    setIsCreatingNewReturn(false);
  };

  const handleCloseModal = () => {
    setSelectedBox(null); 
  };
  
  const handleEditClick = (box) => {
    setSelectedBox(box);
    setIsEditing(true); 
    setIsCreatingNewReturn(false); 
  };

  const handleNewReturnClick = () => {
    setIsCreatingNewReturn(true);
  };

  const archiveReturns = async () => {
    if (window.confirm('Are you sure you want to submit these returns?')) {
      try {
        const response = await fetch('/api/archive-returns', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });
  
        if (response.ok) {
          alert('Your returns have been submitted successfully.');
          setBoxes([]); 
        } else {
          console.error('Error submitting returns:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    }
  };  

  return (
    <div className={styles.returnPage}>
      {isCreatingNewReturn ? (
        <NewForm onAddBox={addBox} onCancel={handleBackClick} />
      ) : isEditing ? (
        <EditBoxForm box={selectedBox} onUpdateBox={updateBox} onClose={() => setIsEditing(false)} />
      ) : (
        <div className={styles.listBox}>
          <div className={styles.listHeader}>
            {!showSearch && ( 
              <button className={styles.newReturnBtn} onClick={handleNewReturnClick}>
                <IoCreateOutline style={{ fontSize: '1.5em' }} /> New Box
              </button>
            )}
            <div className={styles.searchContainer}>
              {showSearch && (
                <input
                  type="text"
                  placeholder="Search by Ref #"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className={`${styles.searchInput} ${showSearch ? styles.searchInputVisible : ''}`}
                  onBlur={() => setShowSearch(false)} 
                />
              )}
              {!selectedBox && ( 
                <IoSearchOutline 
                  size={25} 
                  className={`${styles.searchIcon} ${showSearch ? styles.searchIconHidden : ''}`} 
                  onClick={handleSearchIconClick} 
                />
              )}
            </div>
          </div>
          <div className={styles.list}>
            <DisplayList boxes={boxes} onDelete={deleteBox} onBoxClick={handleBoxClick}/>
            <BoxDetailsModal box={selectedBox} onClose={handleCloseModal} onEditClick={handleEditClick} getClientName={getClientName} />
          </div>
          <div className={styles.buttonGroup}>
            <button 
              type="button" 
              onClick={navToLanding}
              className={styles.backButton}
            >
              Back
            </button>
            <button 
              onClick={archiveReturns} 
              className={styles.exportButton}
            >
              Submit<IoDownloadOutline style={{ fontSize: '1.2em' }} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReturnPage;