import React, { useState } from 'react';
import UserManagement from '../UserManagement/UserManagement.js';
import AdminExport from '../AdminExport/AdminExport.js';
import ClientManagement from '../ClientManagement/ClientManagement.js';
import AdminArchive from '../AdminArchive/AdminArchive.js'; 
import styles from './AdminDashboard.module.css';

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('users');

    const switchTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className={styles.adminDashboard}>
            <nav className={styles.adminNav}>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('users')}
                    disabled={activeTab === 'users'}
                >
                    User Management
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('clients')}
                    disabled={activeTab === 'clients'}
                >
                    Client Management
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('archive')}
                    disabled={activeTab === 'archive'}
                >
                    Archive
                </button>
                <button 
                    className={styles.navButton} 
                    onClick={() => switchTab('export')}
                    disabled={activeTab === 'export'}
                >
                    Export
                </button>
            </nav>
            <div className={styles.adminContent}>
                {activeTab === 'users' && <UserManagement />}
                {activeTab === 'clients' && <ClientManagement />}
                {activeTab === 'archive' && <AdminArchive />}  
                {activeTab === 'export' && <AdminExport />}
            </div>
        </div>
    );
};

export default AdminDashboard;