import React, { useState } from 'react';
import styles from '../AdminExport/AdminExport.module.css';

const SgdExport = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const handleExportSgdData = async () => {
        try {
            const response = await fetch('/api/sgd/export', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    startDate: startDate || null,
                    endDate: endDate || null
                })
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'sgd_data_export.xlsx');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                console.error('Failed to export SGD data:', response.statusText);
            }
        } catch (error) {
            console.error('Error exporting SGD data:', error);
        }
    };

    return (
        <div className={styles.sgdExport}>
            <h2>SGD Data Export</h2>
            <div className={styles.filterContainer}>
                <input 
                    type="date" 
                    value={startDate} 
                    onChange={(e) => setStartDate(e.target.value)}
                    className={styles.dateInput}
                />
                <input 
                    type="date" 
                    value={endDate} 
                    onChange={(e) => setEndDate(e.target.value)}
                    className={styles.dateInput}
                />
                <button className={styles.exportButton} onClick={handleExportSgdData}>
                    Export
                </button>
            </div>
        </div>
    );
};

export default SgdExport;
