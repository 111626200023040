import React, { useState, useEffect } from 'react';
import { IoCloseCircleOutline } from "react-icons/io5";
import styles from './MasterUserManagement.module.css';
import PasswordResetModal from './PasswordResetModal';

const MasterUserManagement = () => {
    const [users, setUsers] = useState([]);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] = useState(false);

    useEffect(() => {
        fetchLocations();
        fetchUsers();
    }, []);
    
    const fetchLocations = async () => {
        try {
            const response = await fetch('/api/master/locations', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });
            if (response.ok) {
                const data = await response.json();
                setLocations(data);
            } else {
                console.error('Failed to fetch locations:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
    };

    const fetchUsers = async () => {
        try {
            const response = await fetch('/api/master/users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include' // Include credentials for session-based authentication
            });
            if (response.ok) {
                const data = await response.json();
                setUsers(data);
            } else {
                console.error('Failed to fetch users: ', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };  
    
    const openPasswordResetModal = (userId) => {
        setSelectedUserId(userId);
        setIsPasswordResetModalOpen(true);
    };

    const closeModal = () => {
        setIsPasswordResetModalOpen(false);
        setSelectedUserId(null); 
    };             

    const toggleAdminStatus = async (userId, isAdmin) => {
        try {
            const response = await fetch(`/api/master/users/${userId}/toggle-admin`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ isAdmin: !isAdmin }) 
            });
            if (response.ok) {
                fetchUsers(); 
            } else {
                const errorResponse = await response.json();
                console.error('Failed to toggle admin status:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error toggling admin status:', error);
        }
    };

    const toggleMasterStatus = async (userId, isMaster) => {
        try {
            const response = await fetch(`/api/master/users/${userId}/toggle-master`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ isMaster: !isMaster }) // Toggle the master status
            });
            if (response.ok) {
                fetchUsers(); // Refresh the user list after toggling
            } else {
                const errorResponse = await response.json();
                console.error('Failed to toggle master status:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error toggling master status:', error);
        }
    };    

    const addUser = async () => {
        try {
            const response = await fetch('/api/master/users', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ username, password, locationId: selectedLocation }),
                credentials: 'include'
            });
            if (response.ok) {
                fetchUsers(); 
            } else {
                const errorResponse = await response.json();
                console.error('Failed to add user:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };
    
    const deleteUser = async (userId) => {
        try {
            const response = await fetch(`/api/master/users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            });
            if (response.ok) {
                fetchUsers(); 
            } else {
                const errorResponse = await response.json();
                console.error('Failed to delete user:', errorResponse.message);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    }; 

    const handleEditToggle = () => {
        setIsEditMode(!isEditMode);
    };

    return (
        <div className={styles.userManagement}>
            <h2>User Management</h2>
            <div className={styles.userManagementHeader}>
                <div className={styles.userManagementForm}>
                    <input 
                        type="text" 
                        placeholder="Username" 
                        value={username} 
                        onChange={(e) => setUsername(e.target.value)} 
                    />
                    
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />

                    <select value={selectedLocation} onChange={(e) => setSelectedLocation(e.target.value)}>
                        <option value="">Select Location</option>
                        {locations.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.location_name}
                            </option>
                        ))}
                    </select>
                    
                    <button onClick={addUser}>Add User</button>
                    
                    <button onClick={handleEditToggle} className={styles.editButton}>
                        {isEditMode ? "Done" : "Edit"}
                    </button>
                </div>
            </div>
            <table className={styles.userTable}>
                <thead>
                    <tr>
                    <th>Employee Name</th>
                    <th>Location</th>
                    <th>Admin</th>
                    <th>Master</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.username}</td>
                            <td>{user.location_name}</td>
                            <td>
                                <label className={styles.switch}>
                                    <input type="checkbox" checked={user.is_admin} onChange={() => toggleAdminStatus(user.id, user.is_admin)} />
                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                </label>
                            </td>
                            <td>
                                <label className={styles.switch}>
                                    <input type="checkbox" checked={user.master} onChange={() => toggleMasterStatus(user.id, user.master)} />
                                    <span className={`${styles.slider} ${styles.round}`}></span>
                                </label>
                            </td>
                            <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                                {isEditMode && (
                                    <div className={styles.buttonContainer}>
                                    <button onClick={() => openPasswordResetModal(user.id)} className={styles.changePasswordButton}>
                                        Change Password
                                    </button>
                                    <button onClick={() => deleteUser(user.id)} className={styles.deleteButton}>
                                        <IoCloseCircleOutline />
                                    </button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {isPasswordResetModalOpen && (
                <PasswordResetModal
                    isOpen={isPasswordResetModalOpen}
                    onClose={closeModal}
                    userId={selectedUserId}
                />
            )}    
        </div>
    );
};

export default MasterUserManagement;