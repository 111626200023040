import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LandingPage.module.css';

const LandingPage = () => {
  const navigate = useNavigate();

  const navigateToInbound = () => {
    navigate('/inbound');
  };

  const navigateToInventory = () => {
    navigate('/inventory'); 
  };

  const navigateToBarcodeGenerator = () => {
    navigate('/barcode-generator'); 
  };

  return (
    <div className={styles.landingPage}>
      <div className={styles.welcomeMessage}>Welcome</div>
      <button onClick={navigateToInbound} className={styles.landingPageButton}>Inbound</button>
      <button onClick={navigateToInventory} className={styles.landingPageButton}>Inventory</button>
      <button onClick={navigateToBarcodeGenerator} className={styles.landingPageButton}>Label Generator</button>
    </div>
  );
};

export default LandingPage;
