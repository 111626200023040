import React, { useState, useEffect } from 'react';
import styles from '../AdminArchive/AdminArchive.module.css';

const AdminArchive = () => {
    const [archivedReturns, setArchivedReturns] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredReturns, setFilteredReturns] = useState([]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await fetch('/api/admin/clients');
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setClients(data);
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };

        fetchClients();
    }, []);

    useEffect(() => {
        const fetchArchivedReturns = async () => {
            const queryParams = new URLSearchParams({
                client_id: selectedClient, 
            });
            try {
                const response = await fetch(`/api/admin/archived-returns?${queryParams}`);
                if (response.ok) {
                    const data = await response.json();
                    setArchivedReturns(data);
                    setFilteredReturns(data); 
                } else {
                    console.error('Failed to fetch archived returns. Status:', response.status);
                }
            } catch (error) {
                console.error('Error fetching archived returns:', error);
            }
        };

        if (selectedClient) {
            fetchArchivedReturns();
        }
    }, [selectedClient]);

    useEffect(() => {
        console.log("Selected Client:", selectedClient);
        console.log("Archived Returns:", archivedReturns);
    
        const matchesSearchQuery = (returnItem) => {
            const query = searchQuery.toLowerCase();
            const refMatches = returnItem.reference_number.toLowerCase().includes(query);
            const skuMatches = returnItem.skus.some(sku => sku.sku.toLowerCase().includes(query));
            return refMatches || skuMatches;
        };
    
        const filtered = archivedReturns
            .filter(returnItem => {
                
                //console.log(`Client ID: ${returnItem.client_id}, Type: ${typeof returnItem.client_id}`);
                //console.log(`Selected Client: ${selectedClient}, Type: ${typeof selectedClient}`);
                
                const clientMatches = !selectedClient || returnItem.client_id === selectedClient;

                //console.log(`Client Match: ${clientMatches}`);
    
                const date = new Date(returnItem.created_at.split('T')[0] + 'T00:00:00');
                const dateMatches = (!startDate && !endDate) || (date >= new Date(startDate + 'T00:00:00') && date <= new Date(endDate + 'T23:59:59.999'));
                
                //console.log(`Date Match: ${dateMatches}`);
                
                return dateMatches && clientMatches && matchesSearchQuery(returnItem);
            });
    
        setFilteredReturns(filtered);
    }, [startDate, endDate, archivedReturns, selectedClient, searchQuery]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleClientChange = (e) => {
        setSelectedClient(e.target.value ? Number(e.target.value) : '');
    };

    return (
        <div className={styles.archiveContainer}>
            <h2 className={styles.archiveTitle}>Archive</h2>

            <div className={styles.filter}>
                <label htmlFor="client-select" className={styles.label}>Client:</label>
                <select 
                    id="client-select"
                    value={selectedClient} 
                    onChange={handleClientChange}
                    className={styles.input}
                >
                    <option value="">Select a Client</option>
                    {clients.map(client => (
                        <option key={client.id} value={client.id}>{client.name}</option>
                    ))}
                </select>
            </div>

            {selectedClient && (
                <>
                    <div className={styles.filter}>
                        <label htmlFor="searchQuery" className={styles.label}>Search:</label>
                        <input 
                            type="text"
                            id="searchQuery"
                            placeholder="Search by Ref # or SKU"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className={styles.input}
                        />
                    </div>

                    <div className={styles.dateFilters}>
                        <div className={styles.filter}>
                            <label htmlFor="startDate" className={styles.label}>Start Date:</label>
                            <input 
                                type="date"
                                id="startDate"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className={styles.input}
                            />
                        </div>
                        <div className={styles.filter}>
                            <label htmlFor="endDate" className={styles.label}>End Date:</label>
                            <input 
                                type="date"
                                id="endDate"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className={styles.input}
                            />
                        </div>
                    </div>
                                        
                    {filteredReturns.length > 0 ? (
                        <table className={styles.archiveTable}>
                            <thead>
                                <tr>
                                    <th>Employee</th>
                                    {filteredReturns.some(r => r.reference_number) && <th>Ref #</th>}
                                    {filteredReturns.some(r => r.skus && r.skus.length > 0) && <th>SKUs</th>}
                                    {filteredReturns.some(r => r.tracking_number) && <th>Tracking #</th>}
                                    {filteredReturns.some(r => r.location_description) && <th>Location</th>}
                                    {filteredReturns.some(r => r.created_at) && <th>Date</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {filteredReturns.map((returnItem, index) => (
                                    <tr key={index}>
                                        <td>{returnItem.processed_by}</td>
                                        {returnItem.reference_number && <td>{returnItem.reference_number}</td>}
                                        {returnItem.skus && returnItem.skus.length > 0 && (
                                            <td>{returnItem.skus.map((sku, skuIndex) => (
                                                <span key={skuIndex}>{sku.sku}<br /></span>
                                            ))}</td>
                                        )}                                        
                                        {returnItem.tracking_number && <td>{returnItem.tracking_number}</td>}
                                        {returnItem.location_description && <td>{returnItem.location_description}</td>}
                                        {returnItem.created_at && <td>{new Date(returnItem.created_at).toLocaleDateString()}</td>}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p className={styles.noData}>No archived returns available for the selected client and date range.</p>
                    )}
                </>
            )}
        </div>
    );    
};

export default AdminArchive;