import React from 'react';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.title}>Logfret Toolkit</h1>
    </header>
  );
};

export default Header;

/*
import React from 'react';
import styles from './Header.module.css';
import logo from './LogfretLogo.png'; // Import the logo image

const Header = () => {
  return (
    <header className={styles.header}>
      <img src={logo} alt="Company Logo" className={styles.logo} />
    </header>
  );
};

export default Header;
*/