import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import ReturnPage from './components/ReturnPage/ReturnPage';
import ArchivePage from './components/ArchivePage/ArchivePage';
import LandingPage from './components/LandingPage/LandingPage';
import SgdLanding from './components/SGD/SgdLanding';
import SgdInventory from './components/SGD/SgdInventory';
import SgdArchive from './components/SGD/SgdArchive';
import SgdExport from './components/SGD/SgdExport';
import ReflauntOrder from './components/Reflaunt/ReflauntOrder';
import BarcodeGenerator from './components/BarcodeGenerator/BarcodeGenerator';
import Login from './components/Login/Login';
import Register from './components/Registration/Register';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';
import MasterDashboard from './components/Master/MasterDashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ReflauntLanding from './components/Reflaunt/ReflauntLanding';

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await fetch('/api/current_user', {
          credentials: 'include' 
        });
        if (response.ok) {
          const userData = await response.json();
          //console.log('Current user data fetched:', userData);
          setUser(userData); 
        }
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    //console.log('User state updated:', user);
  }, [user]);

  const handleLogin = (userData) => {
    //console.log("Logged in user:", userData);
    setUser(userData); 
  };

  const isAuthenticated = user?.isAuthenticated;
  const isSGDUser = user?.locationId === 5;
  const isReflauntUser = user?.locationId === 6;
  const isMaster = user?.isMaster;
  const isAdmin = user?.isAdmin;

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={isAuthenticated ? (isSGDUser ? <SgdLanding /> : isReflauntUser ? <ReflauntLanding /> : isMaster ? <MasterDashboard /> : isAdmin ? <AdminDashboard /> : <LandingPage />) : <Navigate to="/welcome" />} />
          {isAuthenticated && <Route path="/sgd-inbound" element={<SgdInventory />} />}
          {isAuthenticated && <Route path="/sgd-inventory" element={<SgdArchive />} />}
          {isAuthenticated && <Route path="/sgd-export" element={<SgdExport />} />}
          {isAuthenticated && <Route path="/reflaunt-orders" element={<ReflauntOrder />} />}
          {isAuthenticated && <Route path="/inbound" element={<ReturnPage />} />}
          {isAuthenticated && <Route path="/inventory" element={<ArchivePage />} />}
          {isAuthenticated && <Route path="/barcode-generator" element={<BarcodeGenerator />} />}
          <Route path="/welcome" element={<Login onLogin={handleLogin} />} />
          <Route path="/register" element={<Register />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;