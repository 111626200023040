import React from 'react';
import styles from '../DisplayList/DisplayList.module.css';
import { IoCloseCircleOutline } from 'react-icons/io5';

const ContainerList = ({ containers, onDelete, onItemClick }) => {
  const handleDelete = (event, containerId) => {
    event.stopPropagation();
    onDelete(containerId); 
  };

  return (
    <div className={styles.listBoxContainer}>
      <ul className={styles.listBox}>
        {containers.map((container) => (
          <li key={container.id} className={styles.listItem} onClick={() => onItemClick(container)}>
            Container #{container.container_number}
            <button onClick={(event) => handleDelete(event, container.id)} className={styles.deleteButton}>
              <IoCloseCircleOutline size={20} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContainerList;
