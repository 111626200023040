import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NewContainerForm from './NewContainerForm';
import ContainerList from './ContainerList'; 
import ContainerDetailsModal from './ContainerDetailsModal';
import EditContainerForm from './EditContainerForm';
import styles from '../ReturnPage/ReturnPage.module.css';
import { IoCreateOutline, IoDownloadOutline, IoSearchOutline } from "react-icons/io5";

const SgdInventory = () => {
  const [containers, setContainers] = useState([]);
  const [filteredContainers, setFilteredContainers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [isCreatingNewContainer, setIsCreatingNewContainer] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedContainer, setSelectedContainer] = useState(null);
  const navigate = useNavigate();

  const fetchContainers = async () => {
    try {
      const response = await fetch('/api/sgd_inventory');
      if (response.ok) {
        const data = await response.json();
        const containersToSet = searchQuery
          ? data.filter(container => container.container_number.toLowerCase().includes(searchQuery.toLowerCase()))
          : data;
        setContainers(containersToSet);
        setFilteredContainers(containersToSet);
      } else {
        console.error('Failed to fetch the containers.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    fetchContainers();
  }, [searchQuery]);
    
  const navToLanding = () => {
    navigate('/'); 
  };

  const handleSearchIconClick = () => {
    setShowSearch(true);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const addContainer = (newContainer) => {
    setContainers(prevContainers => [...prevContainers, newContainer]);
    setIsCreatingNewContainer(false);
  };

  const deleteContainer = async (containerId) => {
    if (window.confirm(`Are you sure? This container will be gone forever.`)) {
      try {
        const response = await fetch(`/api/sgd_inventory/${containerId}`, {
          method: 'DELETE',
        });
        if (response.ok) {
          setContainers(containers.filter(currentContainer => currentContainer.id !== containerId));
        } else {
          console.error('Failed to delete the container.');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  const updateContainer = (updatedContainer) => {
    setContainers(prevContainers =>
      prevContainers.map(container => container.id === updatedContainer.id ? updatedContainer : container)
    );

    if (selectedContainer && selectedContainer.id === updatedContainer.id) {
      setSelectedContainer(updatedContainer);
    }
  };

  const handleContainerClick = (container) => {
    setSelectedContainer(container);
  };

  const handleBackClick = () => {
    setIsCreatingNewContainer(false);
  };

  const handleCloseModal = () => {
    setSelectedContainer(null);
  };

  const handleEditClick = (container) => {
    setSelectedContainer(container);
    setIsEditing(true);
    setIsCreatingNewContainer(false);
  };

  const handleNewContainerClick = () => {
    setIsCreatingNewContainer(true);
  };

  const archiveContainers = async () => {
    const containerIds = containers.map(container => container.id); // Assuming each container has an 'id' property
  
    if (window.confirm(`Are you sure you want to archive these containers? This action cannot be undone.`)) {
      try {
        const response = await fetch('/api/sgd-archive', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ containerIds }),
        });
  
        if (!response.ok) {
          throw new Error('Failed to archive containers');
        }
  
        const result = await response.json();
        alert('Your returns have been submitted successfully.');
        fetchContainers(); 
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to archive containers. Please try again.');
      }
    }
  };

  return (
    <div className={styles.returnPage}>
      {isCreatingNewContainer ? (
        <NewContainerForm onAddContainer={addContainer} onCancel={handleBackClick} />
      ) : isEditing ? (
        <EditContainerForm container={selectedContainer} onUpdateContainer={updateContainer} onClose={() => setIsEditing(false)} />
      ) : (
        <div className={styles.listBox}>
          <div className={styles.listHeader}>
            {!showSearch && (
              <button className={styles.newReturnBtn} onClick={handleNewContainerClick}>
                <IoCreateOutline style={{ fontSize: '1.5em' }} /> New Container
              </button>
            )}
            <div className={styles.searchContainer}>
              {showSearch && (
                <input
                  type="text"
                  placeholder="Search by Container #"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className={`${styles.searchInput} ${showSearch ? styles.searchInputVisible : ''}`}
                  onBlur={() => setShowSearch(false)}
                />
              )}
              {!selectedContainer && (
                <IoSearchOutline
                  size={25}
                  className={`${styles.searchIcon} ${showSearch ? styles.searchIconHidden : ''}`}
                  onClick={handleSearchIconClick}
                />
              )}
            </div>
          </div>
          <div className={styles.list}>
            <ContainerList containers={containers} onDelete={deleteContainer} onItemClick={handleContainerClick} />
            <ContainerDetailsModal container={selectedContainer} onClose={handleCloseModal} onEditClick={handleEditClick} />
          </div>
          <div className={styles.buttonGroup}>
            <button 
              type="button" 
              onClick={navToLanding}
              className={styles.backButton}
            >
              Back
            </button>
            <button 
              onClick={archiveContainers} 
              className={styles.exportButton}
            >
              Submit<IoDownloadOutline style={{ fontSize: '1.2em' }} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SgdInventory;