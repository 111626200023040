import React, { useState, useEffect } from 'react';
import styles from '../ArchivePage/ArchivePage.module.css';

const SgdArchive = () => {
    const [archivedContainers, setArchivedContainers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredContainers, setFilteredContainers] = useState([]);

    useEffect(() => {
        const fetchArchivedContainers = async () => {
            const adjustedEndDate = endDate ? new Date(endDate + 'T23:59:59.999') : endDate; // Adjust end date to include the entire day
            const queryParams = new URLSearchParams({
                start_date: startDate,
                end_date: adjustedEndDate ? adjustedEndDate.toISOString().split('T')[0] : '', // Convert adjusted end date back to YYYY-MM-DD format
                search_query: searchQuery,
            });
    
            try {
                const response = await fetch(`/api/sgd-archived-returns?${queryParams.toString()}`);
                if (response.ok) {
                    const data = await response.json();
                    setArchivedContainers(data);
                    setFilteredContainers(data); 
                } else {
                    console.error('Failed to fetch archived containers. Status:', response.status);
                }
            } catch (error) {
                console.error('Error fetching archived containers:', error);
            }
        };
    
        fetchArchivedContainers();
    }, [searchQuery, startDate, endDate]);    

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    return (
        <div className={styles.archiveContainer}>
            <h2 className={styles.archiveTitle}>SGD Archive</h2>

            <div className={styles.searchFilter}>
                <label className={styles.label}>Search:</label>
                <input 
                    type="text" 
                    placeholder="Search by Container # or Reference #"
                    value={searchQuery}
                    onChange={handleSearchChange}
                />
            </div>

            <div className={styles.dateFilter}>
                <label className={styles.label}>Date Range:</label>
                <input 
                    type="date" 
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <input 
                    type="date" 
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
            </div>
                                    
            {filteredContainers.length > 0 ? (
                <table className={styles.archiveTable}>
                    <thead>
                        <tr>
                            <th>Container #</th>
                            <th>Reference Numbers</th>
                            <th>Date Archived</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredContainers.map((container, index) => (
                            <tr key={index}>
                                <td>{container.container_number}</td>
                                <td>
                                    {Array.isArray(container.reference_numbers) ? 
                                        container.reference_numbers.join(", ") : 
                                        JSON.parse(container.reference_numbers || '[]').join(", ")
                                    }
                                </td>
                                <td>{new Date(container.created_at).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p className={styles.noData}>No archived containers available for the selected date range and search query.</p>
            )}
        </div>
    );    
};

export default SgdArchive;
