import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate and Link
import styles from './Register.module.css'; // Import the styles

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isUsernameFocused, setIsUsernameFocused] = useState(false); // Add focus state for username
  const [isPasswordFocused, setIsPasswordFocused] = useState(false); // Add focus state for password
  const navigate = useNavigate(); // Use navigate for redirection

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post('/register', { username, password });
      alert('Registration successful');
      navigate('/welcome'); // Navigate to login after successful registration
    } catch (error) {
      alert('Registration failed: ' + (error.response.data.message || 'Server error'));
    }
  };

  return (
    <div className={styles.loginFormContainer}>
      <div className={styles.loginForm}>
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <div className={`${styles.loginFormField} ${isUsernameFocused || username ? styles.focused : ''}`}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onFocus={() => setIsUsernameFocused(true)}
              onBlur={() => setIsUsernameFocused(false)}
            />
          </div>
          <div className={`${styles.loginFormField} ${isPasswordFocused || password ? styles.focused : ''}`}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
            />
          </div>
          <button className={styles.loginFormButton} type="submit">Register</button>
        </form>
        <p>Have an account? <Link to="/welcome">Login</Link></p> {/* Update text here */}
      </div>
    </div>
  );
};

export default Register;